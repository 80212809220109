<template>
  <v-dialog :value="dialog" persistent max-width="600px">
    <v-card data-testid="container-crud-engine">
      <v-card-title>
        <span class="headline" v-html="getTitle()"></span>
      </v-card-title>
      <v-card-text>
        <v-form data-vv-scope="form-engines" data-testid="form-engine">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Name"
                  v-model="activeItem.name"
                  :error-messages="errors.collect('form-engines.name')"
                  v-validate="'required'"
                  data-vv-name="name"
                  data-testid="name"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Serial number"
                  v-model="activeItem.serial_number"
                  :error-messages="errors.collect('form-engines.serial_number')"
                  v-validate="'required'"
                  data-vv-name="serial_number"
                  data-testid="serial-number"
                />
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="activeItem.is_suspended"
                  label="Bundle suspended"
                  data-vv-validate-on="change"
                  class="pt-0 mt-0"
                  data-testid="is-bundle-suspended"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Description"
                  v-model="activeItem.description"
                  :error-messages="errors.collect('form-engines.description')"
                  data-vv-name="description"
                  data-testid="description"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="activeItem.events_access_key"
                  label="Event link"
                  outlined
                  readonly
                  :messages="'Link will be refreshed after relogin in ASP'"
                  id="event-generated-link"
                  dense
                  :disabled="!activeItem.events_access_key"
                  data-testid="event-link"
                >
                  <template v-slot:append-outer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          @click.stop.prevent="copyToClipboard"
                          :disabled="!activeItem.events_access_key"
                          style="height: 28px; width: 28px"
                          data-testid="btn-copy-event-link"
                        >
                          <v-icon v-on="on">mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      Copy to clipboard
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click.native="closeDialog()"
          data-testid="btn-close"
        >
          Close
        </v-btn>
        <v-btn color="success" text @click.stop="save()" data-testid="btn-save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    activeItem: {
      type: Object,
      default() {
        return {}
      },
    },
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },
  data() {
    return {}
  },
  methods: {
    getTitle() {
      return this.editedIndex == -1
        ? "Add new engine"
        : `<span class="blue-grey--text text--lighten-4">Edit engine</span> ${this.activeItem.name}`
    },

    createEngine(data) {
      let self = this
      self.$store
        .dispatch("enginesCreate", data)
        .then(({ data }) => {
          self.$emit("engine-data-saved")
          self.closeDialog()
          let params = {
            text:
              "Engine " + self.activeItem.name + " was created successfully.",
            show: true,
          }
          self.$root.$emit("snackbar", params)
        })
        .catch(({ response }) => {
          //self.handleValidationErrors(response);
          console.log("error on createEngine")
        })
    },

    editEngine(data, save) {
      let self = this
      let saveData = save || false
      if (saveData) {
        self.$validator.validateAll("form-engines").then((result) => {
          if (result) {
            self.$store
              .dispatch("enginesEdit", data)
              .then(({ data }) => {
                self.$emit("engine-data-saved")
                self.closeDialog()
                let params = {
                  text:
                    "Engine " +
                    self.activeItem.name +
                    " was edited successfully.",
                  show: true,
                }
                self.$root.$emit("snackbar", params)
              })
              .catch(({ response }) => {
                //self.handleValidationErrors(response)
                // console.log('error on editEngine')
              })
          }
        })
      }
    },

    save() {
      let self = this
      let formData = {
        data: {
          name: self.activeItem.name,
          serial_number: self.activeItem.serial_number,
          is_suspended: self.activeItem.is_suspended,
          description: self.activeItem.description,
        },
      }

      if (this.editedIndex > -1) {
        formData["slug"] = self.editedIndex
        self.editEngine(formData, true)
      } else {
        self.createEngine(formData)
      }
    },

    deleteEngine(item) {
      let self = this
      if (confirm("Are you sure you want to delete this item?")) {
        self.loading = true
        self.$store.dispatch("enginesDelete", item).then(({ data }) => {
          self.$emit("engine-data-saved")
          self.loading = false
        })
      }
    },

    closeDialog() {
      let self = this
      this.$emit("update:dialog", false)
      setTimeout(function () {
        self.$validator.errors.clear("form-engines")
      }, 20)
    },

    copyToClipboard() {
      let self = this
      const input = document.getElementById("event-generated-link")
      input.select()
      navigator.clipboard
        .writeText(
          window.location.origin +
            "/events/access/" +
            self.activeItem.events_access_key
        )
        .then(
          function () {
            let params = {
              text: "Event link was copied successful!",
              show: true,
            }
            self.$root.$emit("snackbar", params)
          },
          function (err) {
            let params = {
              text: "Event link was copied without success!",
              show: true,
            }
            self.$root.$emit("snackbar", params)
          }
        )
    },
  },
}
</script>
