<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    persistent
    content-class="dialog-bundle-upgrade"
  >
    <v-form
      v-if="engine"
      data-vv-scope="form-bundle-upgrade"
      key="bundleRegisteredCreateUpdate"
      data-testid="form-bundle-upgrade"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ getTitle() }}</span>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12" md="12" lg="12">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  v-if="errors.has('form-bundle-upgrade.general_error_field')"
                >
                  <v-alert type="error" text>
                    {{
                      errors.first("form-bundle-upgrade.general_error_field")
                    }}
                  </v-alert>
                </v-col>

                <v-col cols="12" sm="12" v-show="selectUpgradeBunle">
                  <SelectProductsAsync
                    v-model="activeProductRegistered.product"
                    outlined
                    :label="
                      engine.active_product
                        ? 'Bundle to upgrade'
                        : 'Bundle to assign'
                    "
                    :multiple="false"
                    item-value="_key"
                    item-text="name"
                    v-validate="selectUpgradeBunle ? 'required' : ''"
                    :error-messages="
                      errors.collect(`form-bundle-upgrade.bundle`)
                    "
                    data-vv-name="bundle"
                    data-vv-validate-on="change"
                    field="bundle"
                    :menu-props="{ maxHeight: 304 }"
                    return-object
                    :customFilters="{
                      engine_key: engine._key,
                      upgrade: true,
                      hs_company_id: engine.company
                        ? engine.company.hs_company_id
                        : null,
                    }"
                    :dialog="dialog"
                    class="form-bundle-upgrade__product"
                    data-testid="product"
                  />
                </v-col>

                <template v-if="!selectUpgradeBunle">
                  <v-col cols="12" sm="12">
                    <v-alert prominent>
                      <v-row align="center">
                        <v-col class="grow">
                          <h3 class="headline">Current bundle</h3>
                          <div>{{ bundleName || "Not selected" }}</div>
                        </v-col>
                        <v-col
                          class="shrink"
                          v-if="dealsHasProducts && engine.company"
                        >
                          <v-btn
                            @click="selectUpgradeBunle = true"
                            color="grey darken-3"
                            data-testid="btn-upgrade"
                            >Upgrade</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </template>

                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  class="ip-whitelist-container"
                  v-if="productRequireIpsWhitelist"
                  data-testid="ip-whitelist-container"
                >
                  <v-combobox
                    v-model="activeProductRegistered.white_list"
                    :items="items"
                    chips
                    outlined
                    label="IP whitelist"
                    multiple
                    prepend-icon="mdi-plus-circle"
                    @click:prepend="dialogWhitelistIps = true"
                    append-icon="false"
                    readonly
                    class="py-0"
                    height="44"
                    data-testid="white-list"
                  >
                    <template
                      v-slot:selection="{ attrs, item, select, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeIP(item)"
                      >
                        <strong>{{ item }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>

                <!--                <v-col cols="12" md="12" v-if="editedIndex !== -1">-->
                <!--                  <v-menu-->
                <!--                    ref="menu"-->
                <!--                    v-model="menu"-->
                <!--                    :close-on-content-click="false"-->
                <!--                    :return-value.sync="activeProductRegistered.expiration_date"-->
                <!--                    transition="scale-transition"-->
                <!--                    offset-y-->
                <!--                    min-width="auto"-->
                <!--                  >-->
                <!--                    <template v-slot:activator="{ on, attrs }">-->
                <!--                      <v-text-field-->
                <!--                        v-model="activeProductRegistered.expiration_date"-->
                <!--                        label="Expiration date"-->
                <!--                        prepend-icon="mdi-calendar"-->
                <!--                        readonly-->
                <!--                        v-bind="attrs"-->
                <!--                        v-on="on"-->
                <!--                        outlined-->
                <!--                      ></v-text-field>-->
                <!--                    </template>-->
                <!--                    <v-date-picker-->
                <!--                      v-model="activeProductRegistered.expiration_date"-->
                <!--                      no-title-->
                <!--                      scrollable-->
                <!--                    >-->
                <!--                      <v-spacer></v-spacer>-->
                <!--                      <v-btn-->
                <!--                        text-->
                <!--                        color="primary"-->
                <!--                        @click="menu = false"-->
                <!--                      >-->
                <!--                        Cancel-->
                <!--                      </v-btn>-->
                <!--                      <v-btn-->
                <!--                        text-->
                <!--                        color="primary"-->
                <!--                        @click="$refs.menu.save(activeProductRegistered.expiration_date)"-->
                <!--                      >-->
                <!--                        OK-->
                <!--                      </v-btn>-->
                <!--                    </v-date-picker>-->
                <!--                  </v-menu>-->
                <!--                </v-col>-->
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            @click="closeDialog()"
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="save()"
            data-testid="btn-save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <AddSingleTextDialog
      :dialog.sync="dialogWhitelistIps"
      @save-field="addNewIp"
      title="Add IP to whitelist"
      field="IP"
      rules="required|validIp"
    ></AddSingleTextDialog>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import "@/validators/ip/ip-validator"
import GeneralMixin from "@/mixins/general.js"
import AddSingleTextDialog from "@/components/commonComponents/AddSingleTextDialog"
import SelectProductsAsync from "@/components/selects/SelectProductsAsync"
import StoreMixin from "@/mixins/store/common.js"

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    engine: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    dialogWhitelistIps: false,
    items: [],
    menu: false,
    selectedEngine: null,
    selectUpgradeBunle: false,
  }),

  components: {
    AddSingleTextDialog,
    SelectProductsAsync,
  },

  mixins: [GeneralMixin, StoreMixin],

  watch: {
    dialog: function (val) {
      if (val) {
        if (!this.engine.active_product && this.engine.company) {
          this.selectUpgradeBunle = true
        }
        this.initData()
      } else {
        this.selectUpgradeBunle = false
      }
    },
  },

  beforeDestroy() {
    this.resetFormData()
  },

  methods: {
    getTitle() {
      let engineName = this.engine ? this.engine.name : ""
      let name = `Manage bundle for engine ${engineName}`
      if (!this.engine.active_product) {
        name = `Assign bundle for engine ${engineName}`
      } else if (this.selectUpgradeBunle) {
        name = `Upgrade bundle for engine ${engineName}`
      }
      return name
    },
    resetFormData() {
      let self = this
      return new Promise((resolve) => {
        self.$validator.errors.clear("form-bundle-upgrade")
        self.$store.dispatch("resetProductRegisteredItem").then(() => {
          resolve()
        })
      })
    },
    initData() {
      let self = this
      self.$store.dispatch("saveLoaderAction", true)
      self.resetFormData().then(() => {
        self.getItemData().then(() => {
          self.$store.dispatch("saveLoaderAction", false)
        })
      })
    },
    removeIP(item) {
      this.activeProductRegistered.white_list.splice(
        this.activeProductRegistered.white_list.indexOf(item),
        1
      )
      this.activeProductRegistered.white_list = [
        ...this.activeProductRegistered.white_list,
      ]
    },
    addNewIp(item) {
      this.activeProductRegistered.white_list.push(item)
    },
    getItemData() {
      return this.$store.dispatch("getProductRegisteredItem", {
        engine: this.engine,
        white_list: this.engine?.active_product?.properties?.white_list || [],
      })
    },
    createRegisteredProduct(formData) {
      let self = this
      self.$store
        .dispatch("createProductRegistered", formData)
        .then(({ data }) => {
          let bundleAssigned = self.getBundleName(
            self.activeProductRegistered.product
          )
          let params = {
            text: `Product <strong>${bundleAssigned}</strong> was registered successfully.`,
            show: true,
          }
          self.closeDialog().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          console.error("Error on createRegisteredProduct", response)
          self.handleValidationErrors(response, "form-bundle-upgrade")
        })
    },
    editRegisteredProduct(formData) {
      let self = this
      self.$store
        .dispatch("editProductRegistered", formData)
        .then(({ data }) => {
          let bundleAssigned = self.getBundleName(
            self.activeProductRegistered.product
          )
          let params = {
            text: `Product <strong>${bundleAssigned}</strong>registry was edited successfully.`,
            show: true,
          }
          self.closeDialog().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-bundle-upgrade")
        })
    },
    save() {
      let self = this
      self.$validator.errors.clear("form-bundle-upgrade")
      self.$validator.validateAll("form-bundle-upgrade").then((result) => {
        console.log("result", result)
        if (result) {
          if (
            !self.selectUpgradeBunle &&
            !self.isNewRegistry &&
            !self.productRequireIpsWhitelist
          ) {
            self.closeDialog()
            return
          }
          let formData = {
            data: {
              //expiration_date: self.activeProductRegistered.expiration_date,
            },
          }
          if (self.isNewRegistry || self.selectUpgradeBunle) {
            formData.data.product_key =
              self.activeProductRegistered.product._key
          }
          if (self.productRequireIpsWhitelist) {
            formData.data.white_list =
              self.activeProductRegistered.white_list || []
          }
          formData["slug"] = self.activeProductRegistered.engine._key
          if (
            this.isNewRegistry ||
            (!this.isNewRegistry && self.selectUpgradeBunle)
          ) {
            self.createRegisteredProduct(formData)
          } else {
            self.editRegisteredProduct(formData)
          }
        }
      })
    },
    close() {
      let self = this
      return new Promise((resolve, reject) => {
        this.$router
          .push({ path: self.pathRedirect })
          .then(() => {
            resolve()
          })
          .catch((err) => {})
      })
    },
    closeDialog() {
      let self = this
      return new Promise((resolve, reject) => {
        self.$emit("update:dialog", false)
        this.resetFormData()
        resolve()
      })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      activeProductRegistered: "activeProductRegistered",
      Pallette: "get_Pallette",
      isSuperUser: "isSuperUser",
      dealsHasProducts: "dealsHasProducts",
    }),
    productRequireIpsWhitelist() {
      return (
        (this.selectUpgradeBunle &&
          this?.activeProductRegistered?.product?.product_type ===
            "BUNDLE_INTERNAL_UNLIM") ||
        (!this.selectUpgradeBunle &&
          this?.engine?.active_product?.product_type ===
            "BUNDLE_INTERNAL_UNLIM")
      )
    },
    pathRedirect: function () {
      return this.isSuperUser ? "/store/bundles/registry" : "/store/bundles"
    },
    isEngineSelectDisabled() {
      return !this.isNewRegistry && this.activeProductRegistered?.engine?._key
        ? true
        : false
    },
    isNewRegistry() {
      return !this.engine.active_product
    },
    bundleName() {
      return this.getBundleName(this.engine.active_product)
    },
  },
}
</script>

<style lang="scss">
.ip-whitelist-container {
  overflow-x: hidden;
}

.form-bundle-upgrade__product {
  .v-input__append-outer {
    margin-left: 0px;
  }

  .v-text-field--outlined .v-input__append-outer {
    margin-top: 0px;
  }
}
</style>
