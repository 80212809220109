<template>
  <div>
    <v-autocomplete
      v-bind="$attrs"
      v-on="$listeners"
      :search-input.sync="filterSearch"
      loading="filterLoading"
      :items="productsList"
      :cache-items="true"
      @select="selectValue"
      v-model="val"
      ref="autocomplete"
    >
      <!-- Pass on all named slots -->
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

      <!-- Pass on all scoped slots -->
      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>

      <template v-slot:append-item>
        <div
          v-if="lastPageItemsListComputed && !filterSearch"
          v-observe-visibility="{
            callback: visibilityChanged,
          }"
          class="selectPagination"
        >
          <span class="pa-2">Loading more items ...</span>
        </div>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title
              v-html="getBundleName(data.item)"
            ></v-list-item-title>
            <v-list-item-subtitle
              v-html="'Deal: ' + data.item.group"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
      <template v-slot:selection="data">
        {{ getBundleName(data.item) }} | {{ data.item.group }}
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
/**
 * Component of select with list of products bundles that are loaded async by chunks, with search
 */
import { mapGetters } from "vuex"
import StoreMixin from "@/mixins/store/common.js"

export default {
  name: "SelectProductsAsync",

  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String, Object],
    },
    customFilters: {
      type: Object,
    },
    // in case when we want sync with dialog to update items list
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    filterSearch: null,
    selectPagination: {
      p: 1,
      pp: 20,
    },
    productsCount: 0,
  }),

  mixins: [StoreMixin],

  mounted: function () {
    this.fetchItems()
  },

  watch: {
    selected(val) {
      this.$emit("update:selected", val)
    },
    filterSearch() {
      this.selectPagination.p = 1
      this.fetchItems()
    },
    value: {
      handler: function (val, oldVal) {
        if (val && val !== oldVal) {
          //this.getDataForSelected()
        }
      },
      immediate: true,
    },
    dialog(val) {
      if (val) {
        this.fetchItems()
      } else {
        this.$refs.autocomplete.cachedItems = []
        this.$store.dispatch("resetDealsList")
      }
    },
    productsList(val) {
      this.productsCount = val.length
    },
  },

  methods: {
    visibilityChanged(e) {
      if (this.lastPageItemsListComputed) {
        e && this.nextPage()
      }
    },
    startPage() {
      this.selectPagination.p = 1
    },
    nextPage() {
      let maxPages = Math.ceil(this.productsCount / this.selectPagination.pp)
      if (this.selectPagination.p <= maxPages - 1) {
        this.filterLoading = true
        this.selectPagination.p += 1
        this.fetchItems()
      }
      this.filterLoading = false
    },
    fetchItems() {
      this.$refs.autocomplete.cachedItems = []
      let fetchData = {
        params: {
          p: this.selectPagination.p,
          pp: this.selectPagination.pp,
          keep: "_key,name",
        },
      }
      if (this.filterSearch) fetchData.params.search = this.filterSearch
      if (this.customFilters)
        fetchData.params = { ...fetchData.params, ...this.customFilters }
      this.$store.dispatch("getDealsList", fetchData)
    },
    selectValue() {
      this.filterSearch = ""
    },
    getDataForSelected() {
      this.$store.dispatch("bundleSelectedInlist", this.value)
    },
  },

  computed: {
    ...mapGetters({
      dealsList: "dealsList",
      dealsCount: "dealsCount",
    }),
    lastPageItemsListComputed() {
      let flag =
        this.productsCount -
          this.selectPagination.p * this.selectPagination.pp >
        0
      return flag
    },
    productsList() {
      let prodsList = []
      this.dealsList.forEach((deal) => {
        if (deal.products.length > 0) {
          // prodsList.push({header: deal.hs_deal_id})
          deal.products.forEach((product) => {
            product.name = product.product_type
            product.group = this.getDealName(deal)
            prodsList.push(product)
          })
          // prodsList.push({divider: true})
        }
      })
      return prodsList
    },
    val: {
      get() {
        return this.value
      },
      set() {},
    },
  },
}
</script>
