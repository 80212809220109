<template>
  <div>
    <v-card data-testid="container-engines">
      <v-toolbar>
        <v-btn fab dark elevation="0" @click.stop="fetchTableData()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-card-title>Engines list</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$can('create', 'engines') || $can('create', 'Superadmin')"
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          @click.stop="createEngine()"
          data-testid="btn-create-engine"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <EngineDialogCrud
          :dialog.sync="dialogEngineSettings"
          :editedIndex="editedIndex"
          :activeItem="activeItem"
          @engine-data-saved="fetchTableData"
          ref="refDialogEngine"
        ></EngineDialogCrud>
      </v-toolbar>

      <v-card-text class="pt-6">
        <DataTableExtended
          ref="table"
          :expanded="getEnginesCompanyList"
          :calculate-widths="true"
          :headers="finalHeaders"
          :items="getEnginesCompanyList"
          class="elevation-0 border"
          :loading="showTableLoader"
          :no-data-text="showTableLoader ? 'Loading…' : 'No data available'"
          :server-items-length="enginesCompanyListCount"
          v-on:init-table-data="fetchTableData"
          :customSearch="filterSearch"
          @update-search="
            (newS) => {
              this.filterSearch = newS
            }
          "
          data-testid="table-engines"
        >
          <template v-slot:top="{ options, updateOptions }">
            <v-row class="pa-3">
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  v-model="filterSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  :style="$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''"
                  clearable
                  data-testid="filter-search"
                />
              </v-col>
              <v-col cols="12" md="3" v-if="$can('read', 'Superadmin')">
                <CompanyFilter
                  :hideDetails="true"
                  v-on:update:selected="onCompanyFilterChange($event)"
                  data-testid="filter-company"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                :offset-md="$can('read', 'Superadmin') ? 0 : 3"
              >
                <v-select
                  v-model="selectFilterOnline"
                  :items="filteOnlineItems"
                  item-text="val"
                  item-value="key"
                  label="Filter by status"
                  return-object
                  outlined
                  data-testid="filter-status"
                />
              </v-col>
            </v-row>
            <SortMobile
              :headers="headers"
              :options="options"
              @update:options="updateOptions"
              v-if="!$vuetify.breakpoint.smAndUp"
            />
          </template>
          <!-- Body -->
          <!--          <template v-slot:[`item._active_preset.name`]="{ item }">-->
          <!--            {{ item._active_preset ? item._active_preset.name : "" }}-->
          <!--          </template>-->
          <template v-slot:[`item.company_name`]="{ item }">
            <div
              v-if="$can('read', 'Superadmin') || $can('read', 'engines')"
              data-testid="engine-company-name"
            >
              {{ item.company_name }}
            </div>
          </template>
          <!--          <template v-slot:[`item._active_preset.role`]="{ item }">-->
          <!--            {{ item._active_preset ? item._active_preset.role : "display" }}-->
          <!--          </template>-->
          <template v-slot:[`item.is_online`]="{ item }">
            <span
              :class="
                item.is_online
                  ? Pallette.statusText.active
                  : Pallette.statusText.notActive
              "
              data-testid="engine-status"
            >
              <span class="overline" data-testid="status-icon">
                <i class="fa fa-circle mx-2"></i>
              </span>
              <span class="font-weight-regular" data-testid="status-text">
                {{ item.is_online ? "Online" : "Offline" }}
              </span>
            </span>
          </template>

          <template v-slot:[`item.current_license.type`]="{ item }">
            <div data-testid="engine-license">
              {{
                getLicenseTypeName(
                  item.current_license ? item.current_license.type : ""
                )
              }}
            </div>
          </template>

          <template v-slot:[`item.active_product.product_type`]="{ item }">
            <div data-testid="engine-bundle">
              {{ getBundleName(item.active_product) }}
            </div>
          </template>

          <template v-slot:[`item.last_user`]="{ item }">
            <span data-testid="engine-last-user">
              {{ getFullname(item.user, true) }}
            </span>
          </template>

          <template v-slot:[`item.last_login`]="{ item }">
            <div
              v-if="$can('read', 'Superadmin') || $can('read', 'engines')"
              data-testid="engine-last-login"
            >
              {{ item.last_login | unixDateTimeFormat }}
            </div>
          </template>
          <template v-slot:[`item.app_version`]="{ item }">
            <div
              v-html="getAppVersion(item)"
              data-testid="engine-app-version"
            ></div>
          </template>
          <template
            v-slot:[`item.menu`]="{ item }"
            v-if="$vuetify.breakpoint.smAndUp"
          >
            <div
              class="text-center d-flex align-center justify-space-around"
              v-if="$can('update', 'Superadmin') || $can('update', 'engines')"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="Pallette.actionButtons.generate"
                    v-on="on"
                    @click="upgradeBundleLicense(item)"
                    class="clear-padding"
                    data-testid="btn-manage-bundle"
                  >
                    <v-icon>mdi-license</v-icon>
                  </v-btn>
                </template>
                <span>Manage bundle</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="Pallette.actionButtons.edit"
                    v-on="on"
                    @click="editEngine(item)"
                    class="clear-padding"
                    data-testid="btn-edit-engine"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit engine</span>
              </v-tooltip>

              <v-tooltip top v-if="isSuperUser">
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="Pallette.actionButtons.delete"
                    v-on="on"
                    @click="deleteEngine(item)"
                    class="clear-padding"
                    data-testid="btn-delete-engine"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete engine</span>
              </v-tooltip>
            </div>
          </template>
          <template
            v-slot:expanded-item="{ headers, item }"
            v-if="
              !$vuetify.breakpoint.smAndUp &&
              ($can('update', 'engines') || $can('update', 'Superadmin'))
            "
          >
            <td :colspan="headers.length">
              <v-row align="center" justify="space-around">
                <VerticalButton
                  @click.native="editEngine(item)"
                  v-if="
                    $can('update', 'Superadmin') || $can('update', 'engines')
                  "
                  icon="mdi-pencil"
                  text="Edit engine"
                  :color="Pallette.actionButtons.edit"
                  data-testid="btn-edit-engine"
                />
              </v-row>
            </td>
          </template>
        </DataTableExtended>
        <!-- Body -->
      </v-card-text>
    </v-card>

    <EngineBundleUpgradeDialog
      :dialog.sync="dialogBundleUpgrade"
      :engine="currentEngine"
    />

    <Confirm ref="confirmDialog"></Confirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { cloneDeep, isUndefined, forEach } from "lodash"
import EngineDialogCrud from "./EngineDialogCrud"
import CompanyFilter from "@/components/filters/CompanyFilter.vue"
import BackButtonMixin from "@/mixins/close-modal-on-backbutton.js"
import CompanyMixin from "@/mixins/group-mixin.js"
import VerticalButton from "@/components/buttons/VerticalButton"
import EngineBundleUpgradeDialog from "@/views/store/deals/EngineBundleUpgradeDialog"
import StoreMixin from "@/mixins/store/common.js"
import Confirm from "@/components/Confirm"
import DataTableExtended from "@/components/table/DataTableExtended"
import SortMobile from "@/components/table/SortMobile"
import GeneralMixin from "@/mixins/general"

export default {
  components: {
    EngineDialogCrud,
    CompanyFilter,
    VerticalButton,
    EngineBundleUpgradeDialog,
    Confirm,
    DataTableExtended,
    SortMobile,
  },

  mixins: [BackButtonMixin, CompanyMixin, StoreMixin, GeneralMixin],

  data() {
    return {
      dialogEngineSettings: false,
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Company",
          align: "left",
          value: "company_name",
          sortable: true,
          width: 120,
        },
        {
          text: "Serial number",
          align: "left",
          value: "serial_number",
          sortable: true,
        },
        {
          text: "Status",
          align: "center",
          value: "is_online",
          sortable: false,
        },
        {
          text: "App version",
          align: "left",
          value: "app_version",
          sortable: true,
          width: 120,
        },
        {
          text: "License",
          align: "left",
          value: "current_license.type",
          sortable: false,
          width: 150,
        },
        {
          text: "Bundle",
          align: "left",
          value: "active_product.product_type",
          sortable: false,
          width: 150,
        },
        {
          text: "Last User",
          value: "last_user",
          sortable: false,
        },
        // @todo temporary columns for preset name and type will be disabled, maybe later will be showed if needed
        // {
        //   text: 'Preset',
        //   align: 'left',
        //   value: '_active_preset.name',
        //   sortable: false,
        //   width: 120
        // },
        // {
        //   text: 'Type',
        //   align: 'left',
        //   value: '_active_preset.role',
        //   sortable: false,
        // },
        {
          text: "Last login",
          align: "left",
          value: "last_login",
          sortable: true,
          width: 120,
        },
        {
          text: "",
          value: "menu",
          align: "center",
          sortable: false,
          class: "th-clear-padding",
          width: 100,
        },
      ],
      engines: [],
      editedIndex: -1,
      activeItem: {
        name: "",
        serial_number: "",
        description: "",
      },
      defaultItem: {
        name: "",
        serial_number: "",
        description: "",
      },
      selectFilterOnline: { key: null, val: "" },
      filteOnlineItems: [
        { key: null, val: "" },
        { key: true, val: "Online" },
        { key: false, val: "Offline" },
      ],
      dialogBundleUpgrade: false,
      currentEngine: null,
      filterSearch: "",
      filterByCompany: null,
    }
  },

  mounted: function () {
    if (!this.$can("read", "engines")) {
      if (!this.$can("read", "Superadmin"))
        this.$router.push({ name: "home" }).catch((err) => {
          // catch router push
        })
    }
  },

  watch: {
    dialogEngineSettings(val) {
      val || this.close()
    },
    filterSearch() {
      this.fetchTableData()
    },
    filterByCompany() {
      this.fetchTableData()
    },
    "selectFilterOnline.key"() {
      this.fetchTableData()
    },
    dialogBundleUpgrade(val) {
      if (!val) {
        this.fetchTableData()
      }
    },
  },

  methods: {
    onCompanyFilterChange(event) {
      this.filterByCompany = event
    },
    fetchTableData: function () {
      let self = this
      let getParams = self.$refs.table.getTableServerParams()
      getParams.show_active_preset = 1
      if (self.filterByCompany) getParams.company_key = self.filterByCompany
      if (self.filterSearch !== "") getParams.search = self.filterSearch
      if (self.selectFilterOnline.key !== null)
        getParams.is_online = self.selectFilterOnline.key
      self.$store.dispatch("enginesCompanyGetList", { params: getParams })
    },
    createEngine() {
      this.dialogEngineSettings = true
      this.activeItem = cloneDeep(this.defaultItem)
      this.editedIndex = -1
    },
    editEngine(item) {
      this.dialogEngineSettings = true
      this.activeItem = cloneDeep(item)
      this.editedIndex = item._key
    },
    close() {
      this.dialogEngineSettings = false
      setTimeout(() => {
        this.editedIndex = -1
      }, 300)
    },
    getAppVersion(item) {
      let versionsArr = []
      if (!isUndefined(item.app_version)) {
        forEach(item.app_version, (val, key) => {
          versionsArr.push(key + " v" + item.app_version[key])
        })
      }
      return versionsArr.length > 0 ? versionsArr.join("<br>") : ""
    },
    upgradeBundleLicense(item) {
      this.currentEngine = item
      this.dialogBundleUpgrade = true
    },
    deleteEngine(item) {
      let self = this
      self.$refs.confirmDialog
        .open("Delete", "Are you sure you want to delete this Engine?", {
          color: "red darken-4",
        })
        .then((confirm) => {
          if (confirm) {
            self.$store.dispatch("saveTableLoaderAction", true)
            self.$store.dispatch("enginesDelete", item).then(({ data }) => {
              self.fetchTableData()
              self.$store.dispatch("saveTableLoaderAction", false)
            })
          }
        })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      getEnginesCompanyList: "getEnginesCompanyList",
      enginesCompanyListCount: "enginesCompanyListCount",
      Pallette: "get_Pallette",
      isSuperUser: "isSuperUser",
    }),

    finalHeaders() {
      let hdrs = this.headers
      if (this.isSuperUser) {
        let actionHeaderIndex = hdrs.findIndex((h) => h.value === "menu")
        if (actionHeaderIndex) {
          hdrs[actionHeaderIndex].width = 140
        }
      }
      return hdrs
    },
  },
}
</script>
